import React from 'react'

import DarkPageTemplate from '../templates/dark-page'

import Seo from '../components/seo'
import Content from '../components/Content'
import Carousel from '../components/Carousel'

class Test extends React.Component {
  render() {
    const sampleText =
      'This is a standard paragraph. Sed fames a ut adipiscing auctor mollis hendrerit aenean vestibulum a aptent a a rhoncus facilisi pretium adipiscing ullamcorper vitae id something in italics conubia mattis ipsum vestibulum mi laoreet. Here is something in bold. Et dui per faucibus risus sodales ullamcorper vel a adipiscing taciti bibendum pretium hac adipiscing condimentum vestibulum a tellus ante parturient molestie inline link phasellus sagittis ullamcorper.'
    return (
      <DarkPageTemplate>
        <Seo
          title="Super Good Software | Spree and Solidus Specialists"
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
        <Content theme={'night'}>
          <h1>Page Title</h1>
          <p>
            This is a standard paragraph. Sed fames a ut adipiscing auctor
            mollis hendrerit aenean vestibulum a aptent a a rhoncus facilisi
            pretium adipiscing ullamcorper vitae id something in italics conubia
            mattis ipsum <strong>something in bold </strong>vestibulum mi
            laoreet. Here is something in bold. Et dui per faucibus risus
            sodales ullamcorper <a href="/">inline link</a> vel a adipiscing
            taciti bibendum pretium hac adipiscing condimentum vestibulum a
            tellus ante parturient molestie inline link phasellus sagittis
            ullamcorper/
          </p>
          <div className={'subheading'}>
            This is the subheading. Elit leo suspendisse ullamcorper etiam
            maecenas tempor commodo eu a scelerisque lorem suscipit a malesuada
            dui ligula adipiscing in amet.
          </div>
          <p>{sampleText}</p>
          <p>{sampleText}</p>
          <h2>Heading 1, H2 tag and styling</h2>
          <p>{sampleText}</p>
          <h3>Heading 2, H3 tag and styling</h3>
          <p>{sampleText}</p>
          <ol>
            <li>
              Clarify the tasks by adding the detail you need to complete them
            </li>
            <li>Organize them into your prioritized to-do list</li>
            <li>Review them to make sure your next steps are clear</li>
            <li>Attack them one by one during periods of execution</li>
          </ol>
          <ul>
            <li>
              Clarify the tasks by adding the detail you need to complete them
            </li>
            <li>Organize them into your prioritized to-do list</li>
            <li>Review them to make sure your next steps are clear</li>
            <li>Attack them one by one during periods of execution</li>
          </ul>
          <h4>Heading 3, H4 tag and styling</h4>
          <p>{sampleText}</p>
          <hr />
          <blockquote>
            This is a blockquote. A one-time solution can save you from doing
            something a thousand times over.
            <div class={'caption'}>- Cite Author</div>
          </blockquote>
          <hr />
          <h3>Heading 2, H3 tag and styling</h3>
          <ol>
            <li>
              Clarify the tasks by adding the detail you need to complete them
            </li>
            <li>Organize them into your prioritized to-do list</li>
            <li>Review them to make sure your next steps are clear</li>
            <li>Attack them one by one during periods of execution</li>
          </ol>
          <ul>
            <li>
              Clarify the tasks by adding the detail you need to complete them
            </li>
            <li>Organize them into your prioritized to-do list</li>
            <li>Review them to make sure your next steps are clear</li>
            <li>Attack them one by one during periods of execution</li>
          </ul>
          <Carousel />
          <h3>Heading 2, H3 tag and styling</h3>
          <p>{sampleText}</p>
        </Content>
      </DarkPageTemplate>
    )
  }
}

export default Test
